import React, { useState, useEffect, useMemo } from 'react'
import {
    Card,
    Table,
    Stack,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    Avatar,
    Grid,
    FormControl,
    Select,
    InputLabel,
    Chip,
    MenuItem,
    CircularProgress,
    ListItem,
    ListItemButton,
    List,
} from '@mui/material';
import Toast from 'components/Toast';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { assignRoleToUser, getAllOrgDepartment, getAllOrgDesignation, getAllOrgPracticeArea, getAssignedUserToRole, unAssignRoleToUser } from 'services/UserAccessMasterService';
import { UserListHead } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { getUsersOfOrganisations } from 'services/OrganizationService';
import { useAccount } from 'context/AccountProvider';
import UserModalToolbar from 'views/NewMatter/UserModalToolbar';
import NewModalComponent from 'components/NewModalComponent';
import { toggleItemInArray, toggleObjectInArray } from 'components/CommonFunction';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TABLE_HEAD = [
    { id: 'name', label: 'User Name', alignRight: false },
    { id: '', label: '', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _?.first_name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis.map((el) => el[0]);
}


function AssignRoleToUserPopup({ id, handleClose, fetchOrgRoleData }) {
    const { userDetails } = useAccount();
    const [allUsers, setAllUsers] = useState([]);
    const [assignedUser, SetAssignedUser] = useState([])
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');
    const [departmentData, setDepartmentData] = useState([])
    const [pracAreaData, setPracAreaData] = useState([])
    const [designationData, setDesignationData] = useState([])
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });




    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    useEffect(() => {
        fetchDepartment()
        fetchPracticeArea()
        fetchDesignation()
        fetchAssignedUserToRole()
        getAllUsersHandler()
    }, [userDetails?.organisation?.id])


    //get all userlist of organisation
    const getAllUsersHandler = async () => {
        setFilterLoading(true)
        const res = await getUsersOfOrganisations(userDetails?.organisation?.id);

        if (res && res.length > 0) {
            setAllUsers(res.filter((_) => _.is_active === true))
            setFilterLoading(false)
        }
        else {
            setFilterLoading(false)
            setAllUsers([])
            setToastData({
                open: true,
                status: 'error',
                message: res.response.data.message || 'Something went wrong'
            });
        }

    };

    //fetch assign user to role
    const fetchAssignedUserToRole = async () => {
        const res = await getAssignedUserToRole(id);

        if (res?.data) {
            SetAssignedUser(res?.data)
            let userIds = res?.data.map(item => item.user);
            setSelected(userIds);
        }
        else {
            SetAssignedUser([])
        }
    }



    //fetch all Organization department
    const fetchDepartment = async () => {
        const res = await getAllOrgDepartment();
        if (res?.data) {
            setDepartmentData(res?.data)
            setDisplayedDPOptions(res?.data)
        }
        else {
            setDepartmentData([])
        }
    }

    //fetch all Organization Practice Area
    const fetchPracticeArea = async () => {
        const res = await getAllOrgPracticeArea();
        if (res?.data) {
            setPracAreaData(res?.data)
            setDisplayedPracOptions(res?.data)
        }
        else { setPracAreaData([]) }
    }

    //fetch all Organization Designation 
    const fetchDesignation = async () => {
        const res = await getAllOrgDesignation();
        if (res?.results) {
            setDesignationData(res?.results)
            setDisplayedDesigOpt(res?.results)
        }
        else { setDesignationData([]) }
    }




    const filteredScreen = applySortFilter(allUsers, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredScreen.length === 0;


    // ============================================================

    const containsText = (text, searchText) => text.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

    // Report To popup Code Start //
    const [reportTo, setReportTo] = useState([])




    // for depart area dropdown 
    const [selectedDPOptions, setSelectedDPOptions] = useState([]);
    const [searchDPText, setSearchDPText] = useState("")
    const [displayedDPOptions, setDisplayedDPOptions] = useState([])

    const handleDPSearchText = useMemo(() => {
        setDisplayedDPOptions(departmentData.filter((option) => containsText(option, searchDPText)))
    }, [searchDPText]);


    const handleDPSelect = (event, option) => {
        debugger
        const newSelectedDPOptions = selectedDPOptions.some((_) => _.id === option.id)
            ? selectedDPOptions.filter(item => item.id !== option.id) // Unselect if already selected
            : [...selectedDPOptions, option]; // Add to selection if not selected
        debugger
        setSelectedDPOptions(newSelectedDPOptions);
        setUserValues((prev) => ({ ...prev, department: newSelectedDPOptions.flatMap(item => item.id) }));
    };

    // for depart area dropdown End
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedPracAreas, setSelectedPracAreas] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [selectedDesignations, setSelectedDesignations] = useState([]);
    const [filterLoading, setFilterLoading] = useState(false)



    // Handle Search Input
    const handleSearch = (event) => {
        setFilterName(event.target.value);
    };

    // Handle Practice Area Selection
    const handlePracAreaChange = (event) => {
        setSelectedPracAreas(event.target.value);
    };

    // Handle Department Selection
    const handleDepartmentChange = (event) => {
        setSelectedDepartments(event.target.value);
    };

    // Handle Designation Selection
    const handleDesignationChange = (event) => {
        setSelectedDesignations(event.target.value);
    };

    // Filter Data
    const filteredData = allUsers?.filter((item) => {
        const matchesSearch = item.first_name.toLowerCase().includes(filterName.toLowerCase());
        const matchesPracticeArea = selectedPracAreas.length === 0 || item.practice_area.some(pa => selectedPracAreas.includes(pa.practice_area.name));
        const matchesDepartment = selectedDepartments.length === 0 || selectedDepartments.includes(item.department);
        const matchesDesignation = selectedDesignations.length === 0 || selectedDesignations.includes(item?.designation?.name);
        debugger
        return matchesSearch && matchesDepartment && matchesDesignation && matchesPracticeArea;
    });

    // Report To popup Code End //



    //on check unchecked assign plan to user function
    const handleAssignUserToRole = async (event, userId) => {
        console.log("selected", selected);

        debugger

        const payload = {
            user_id: selected,
            role_id: id
        };

        const res = await assignRoleToUser(payload);

        if (res?.response?.status === 400) {
            setToastData({
                open: true,
                status: 'warning',
                message: res?.response?.data?.message || res.message
            });
            fetchOrgRoleData()
            handleClose()
        }
        else {
            setToastData({
                open: true,
                status: 'success',
                message: selected.length === 0 ? 'User Role mapping updated successfully.' : res?.response?.data?.message || res.message
            });
            fetchAssignedUserToRole()
            fetchOrgRoleData()
            setTimeout(() => {
                handleClose()
            }, 1000);
        }


        // old code for delete mapping 
        // if (event.target.checked === true) {
        //     const payload = {
        //         user_id: userId,
        //         role_id: id
        //     };
        //     const res = await assignRoleToUser(payload);

        //     if (res?.response?.status === 400) {
        //         setToastData({
        //             open: true,
        //             status: 'warning',
        //             message: res?.response?.data?.message || res.message
        //         });
        //     }
        //     else {
        //         setToastData({
        //             open: true,
        //             status: 'success',
        //             message: res?.response?.data?.message || res.message
        //         });
        //         fetchAssignedUserToRole()
        //     }
        // }
        // else {
        //     let RemoveUserId = assignedUser?.filter((_) => _.user === userId)[0].id
        //     const res = await unAssignRoleToUser(RemoveUserId);
        //     if (res.message === "Use Role mapping deleted successfully.") {
        //         setToastData({
        //             open: true,
        //             status: 'success',
        //             message: res?.response?.data?.message || res.message
        //         });
        //         fetchAssignedUserToRole()
        //     }
        //     else {
        //         setToastData({
        //             open: true,
        //             status: 'error',
        //             message: res?.response?.data?.message || res.message
        //         });
        //     }
        // }
    };

    const handleClick = (row, userId) => {
        setSelected((prevItems) => toggleItemInArray(prevItems, userId));
        setReportTo((prevItems) => toggleObjectInArray(prevItems, row));
    }

    return (
        <>

            <Stack px={3} pt={3} direction="column" alignItems="center" spacing={1} height={'calc(100vh - 192px)'}>

                <Stack mt={1} sx={{ width: '100%' }} alignContent={'flex-start'}>
                    <h5> Report To </h5>
                </Stack>

                {/* <Stack className='reportto_wrp' sx={{ width: '100%' }} > */}
                <Grid container spacing={2} fullWidth sx={{ height: 'calc(100% - 40px)' }} alignContent={'flex-start'} pt={3}>
                    <Grid item xs={4}>

                        <UserModalToolbar filterName={filterName} onFilterName={handleSearch} />

                        <FormControl size='small' sx={{ marginBottom: '24px', marginTop: '24px' }} fullWidth>
                            <InputLabel id="designationFilter">Designation</InputLabel>
                            <Select
                                className={selectedDesignations?.length ? "selected report" : "report "}
                                id="designationFilter"
                                label="Designation"
                                multiple
                                value={selectedDesignations}
                                onChange={handleDesignationChange}
                                renderValue={(selected) => (
                                    <div>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} sx={{ mr: '5px' }} />
                                        ))}
                                    </div>
                                )}
                            >
                                {[...new Set(designationData.map((item) => item.name))].map((desig) => (

                                    <MenuItem
                                        key={desig} value={desig}
                                        className="filter-list-item customcheck"
                                        sx={{ padding: '0px 16px' }}
                                    >   <input
                                            type="checkbox"
                                            checked={selectedDesignations.indexOf(desig) > -1}
                                        />
                                        <label style={{ width: '100%', justifyContent: 'flex-start' }}>
                                            {desig || '-'}
                                        </label>
                                    </MenuItem>

                                ))}
                            </Select>
                        </FormControl>


                        {userDetails?.organisation?.org_type == 'law_firm' ?

                            // show practice area if orgnasition law_firm 
                            <FormControl size='small' sx={{ marginBottom: '24px' }} fullWidth>
                                <InputLabel id="pracAreaFilter"> Practice Area </InputLabel>
                                <Select
                                    className={selectedPracAreas?.length ? "selected report" : "report "}
                                    id="pracAreaFilter"
                                    label="Practice Area"
                                    multiple
                                    value={selectedPracAreas}
                                    onChange={handlePracAreaChange}
                                    renderValue={(selected) => (
                                        <div>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} sx={{ mr: '5px' }} />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {[...new Set(pracAreaData.map((item) => item.name))].map((dept) => (
                                        <MenuItem
                                            key={dept} value={dept}
                                            className="filter-list-item customcheck"
                                            sx={{ padding: '0px 16px' }}
                                        >   <input
                                                type="checkbox"
                                                checked={selectedPracAreas.indexOf(dept) > -1}
                                            />
                                            <label style={{ width: '100%', justifyContent: 'flex-start' }}>
                                                {dept || '-'}
                                            </label>
                                        </MenuItem>
                                    ))}
                                </Select >
                            </FormControl>

                            :

                            // show Department if orgnasition enterprise 
                            <FormControl size='small' sx={{ marginBottom: '24px' }} fullWidth>
                                <InputLabel id="departmentFilter"> Department </InputLabel>
                                <Select
                                    className={selectedDepartments?.length ? "selected report" : "report "}
                                    id="departmentFilter"
                                    label="Department"
                                    multiple
                                    value={selectedDepartments}
                                    onChange={handleDepartmentChange}
                                    renderValue={(selected) => (
                                        <div>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </div>
                                    )}
                                >

                                    {[...new Set(departmentData.map((item) => item.name))].map((dept) => (
                                        <MenuItem
                                            key={dept} value={dept}
                                            className="filter-list-item customcheck"
                                            sx={{ padding: '0px 16px' }}
                                        >   <input
                                                type="checkbox"
                                                checked={selectedDepartments.indexOf(dept) > -1}
                                            />
                                            <label style={{ width: '100%', justifyContent: 'flex-start' }}>
                                                {dept || '-'}
                                            </label>
                                        </MenuItem>
                                    ))}
                                </Select >
                            </FormControl>
                        }

                    </Grid>

                    <Grid item xs={8} height={'100%'} overflow={'auto'}>
                        <Stack sx={{ minHeight: "400px", maxHeight: 'calc(100vh - 297px)', overflow: 'auto', margin: '0', paddingTop: '0' }}>
                            {filterLoading ?
                                <Stack alignItems={'center'} justifyContent={'center'} style={{ height: '100%' }}>
                                    <CircularProgress />
                                </Stack>
                                :
                                <List sx={{ paddingTop: '0', height: '100%' }}>
                                    {/* <ListItem disablePadding className="filter-list-item customcheck" >
                                                <ListItemButton disablePadding sx={{ padding: '0 5px', }} >
                                                    <input type="checkbox"
                                                        id={'All'}
                                                    // checked={isItemSelected} 
                                                    // onChange={(event) => {
                                                    //     handleClick(event, row?.id)
                                                    // }}
                                                    />
                                                    <label htmlFor={'All'} style={{ width: '100%', justifyContent: 'flex-start' }} > All </label>
                                                </ListItemButton>
                                            </ListItem> */}
                                    {console.log("filteredData", filteredData)}
                                    {
                                        filteredData?.length ?
                                            filteredData?.map((row) => {
                                                const { first_name, last_name, id } = row;
                                                const isItemSelected = selected.indexOf(id) !== -1;
                                                return (

                                                    <ListItem disablePadding
                                                        className="filter-list-item customcheck"
                                                        key={row.id}
                                                    >
                                                        <ListItemButton disablePadding sx={{ padding: '0 5px' }} >
                                                            <input type="checkbox"
                                                                id={first_name}
                                                                checked={isItemSelected}
                                                                onChange={(event) => {
                                                                    handleClick(row, row?.id)
                                                                }}
                                                            />
                                                            <label htmlFor={first_name}
                                                                style={{ width: '100%', justifyContent: 'flex-start', textTransform: 'capitalize' }}
                                                            >
                                                                {first_name || '-'} {last_name || ''}
                                                            </label>
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })
                                            :

                                            <li>
                                                <Stack align="center" colSpan={4} sx={{ py: 3 }}>
                                                    No User Found
                                                </Stack>
                                            </li>

                                    }
                                </List>
                            }
                        </Stack>
                    </Grid>
                </Grid>
                {/* </Stack> */}

            </Stack>

            <Stack
                className='updatelicense_btn'
                flexDirection={'row'}
                justifyContent={'flex-end'}
                gap={1}
                p={3}
                style={{ width: "100%" }}>
                <button
                    className='outline_btn'
                    onClick={handleClose}
                >
                    Cancel
                </button>
                <button
                    className='blue_btn'
                    sx={{ width: "100%" }}
                    onClick={() => handleAssignUserToRole()}
                >
                    Confirm
                </button>
            </Stack>

            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default AssignRoleToUserPopup