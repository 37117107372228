import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Loader from 'ui-component/Loader';
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, TableContainer, TablePagination, TextField, CardContent, Paper, FormControlLabel, Switch, tableCellClasses, IconButton, Tooltip, Grid } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { UserListHead, UserListToolbar } from 'sections/@dashboard/user';
import SearchNotFound from 'components/SearchNotFound';
import ModalComponent from 'components/Modal';
import { styled } from '@mui/system';
import Toast from 'components/Toast';
import Iconify from 'components/Iconify';
import { createModule, createOrgRoleModule, createScreen, deleteModule, deleteOrgRoleModule, deleteScreen, duplicateOrgRoleModule, getAllModule, getAllOrgDesignation, getAllOrgRoleModule, getAllScreen, updateModule, updateOrgRoleModule } from 'services/UserAccessMasterService';
import OrgRoleMoreMenu from './OrgRoleMoreMenu';
import AssignRoleToUser from './AssignRoleToUserPopup';
import AssignModuleToRole from './AssignModuleToRolePopup';
import AssignModuleToRolePopup from './AssignModuleToRolePopup';
import AssignRoleToUserPopup from './AssignRoleToUserPopup';
import AssignDesignationToRolePopup from './AssignDesignationToRolePopup';
import AssignDepartmentToRolePopup from './AssignDepartmentToRolePopup';
import AssignPracticeAreaToRolePopup from './AssignPracticeAreaToRolePopup';
import StyledTableListHead from 'views/StyledTable/StyledTableListHead';
import { CustomIOSSwitch } from 'components/ISOSwitch';
import moment from 'moment';
import NewModalComponent from 'components/NewModalComponent';
import { getSuperAdminRole } from 'services/AdminAcessService';
import AssignModalScreenAction from './AssignModalScreenAction/AssignModalScreenAction';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    'td ': {
        borderRight: "1px solid #D1D9E0 !important"
    },
    'th ': {
        borderBottom: "1px solid #D1D9E0 !important"
    },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    '&:last-child': {
        borderRight: '0 !important',
    },
    fontSize: '14px',
    fontWeight: 500,
    color: '#404040',
    padding: '10px !important',

}));


const TABLE_HEAD = [
    { id: 'name', label: 'Role Name', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'created', label: 'Created Date', alignRight: false },
    { id: 'role_type', label: 'Role Type', alignRight: false },
    { id: 'module', label: 'Module', alignRight: false },
    { id: 'assignee', label: 'Assignee', alignRight: false },
    { id: '', label: '', alignRight: true },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}


function OrganizationRoleMaster() {
    const [orgRoleData, setOrgRoleData] = useState([])
    const [IsLoading, setIsLoading] = useState(false)
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterName, setFilterName] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [createModal, setCreateModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [assignModal, setAssignModal] = useState(false)
    const [assignToUserModal, setAssignToUserModal] = useState(false)
    const [departmentModule, setDepartmentModule] = useState(false)
    const [designationModule, setDesignationModule] = useState(false)
    const [practiceAreaModule, setPracticeAreaModule] = useState(false)
    const [activeScreenRoleID, setActiveScreenRoleID] = useState('')
    const [activeRoleData, setActiveRoleData] = useState({})
    const [setps, setSetps] = useState(1)
    const [combineList, setCombineList] = useState([])

    const [orgRoleDataValue, setOrgRoleDataValue] = useState({
        name: '',
        // role_type: '',
        description: '',
        status: 'INACTIVE',
    });
    const [prevmodalData, setprevModalData] = useState([])
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    useEffect(() => {
        fetchAdminRoleData()
        fetchOrgRoleData()
    }, [])

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orgRoleData?.length) : 0;

    const filteredRole = applySortFilter(orgRoleData, getComparator(order, orderBy), filterName);

    const isDataNotFound = filteredRole?.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orgRoleData?.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrgRoleDataValue((prev) => ({ ...prev, [name]: value }));
    };

    const handleSwitch = (name, value) => {
        let status
        if (value) {
            status = 'ACTIVE';
        } else {
            status = 'INACTIVE';
        }
        setOrgRoleDataValue((prev) => ({ ...prev, [name]: status }));
    }

    //fetch all module data
    const fetchAdminRoleData = async () => {
        const res = await getSuperAdminRole();
        if (res?.data) {
            setCombineList(res?.data)
        }
        else {
            setCombineList([])
        }
    }

    //fetch all module data
    const fetchOrgRoleData = async (data) => {
        const res = await getAllOrgRoleModule();
        if (res?.data) {
            setOrgRoleData(res?.data)
        }
        else {
            setOrgRoleData([])
        }
    }

    // edit button for role
    const EditModuleStatus = (id, data) => {
        setIsEdit(true)
        setOrgRoleDataValue(data)
        setprevModalData(data)
        setCreateModal(true)
        setSetps(1)
    }


    //handle Close steps
    const handelCloseModal = () => {
        setOrgRoleDataValue({
            name: '',
            role_type: '',
            description: '',
            status: 'INACTIVE',
        })
        setCreateModal(false)
        setIsEdit(false)
        setAssignModal(false)
        setActiveScreenRoleID('')
        setAssignToUserModal(false)
        setDepartmentModule(false)
        setDesignationModule(false)
        setPracticeAreaModule(false)
        setAssignToUserModal(false)
    }


    const findChangedData = (previousObj, currentObj) => {
        const changedData = {};
        for (const key in previousObj) {
            if (previousObj[key] !== currentObj[key]) {
                changedData[key] = currentObj[key];
            }
        }
        return changedData;
    };


    // handle create update module
    const handleCreateUpdateModule = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        let payload = {
            ...orgRoleDataValue,
            status: orgRoleDataValue?.status
        }
        if (isEdit) {
            const newChangedKeys = findChangedData(prevmodalData, orgRoleDataValue);
            const res = await updateOrgRoleModule(orgRoleDataValue?.id, newChangedKeys);
            if (res?.message === "Organization role data updated successfully.") {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                setIsLoading(false)
                setCreateModal(!createModal)
                setIsEdit(false)
                setSetps(1)
                fetchOrgRoleData();
            }
            else {
                setIsLoading(false)
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || 'Something went wrong'
                });
            }
        }
        else {
            const res = await createOrgRoleModule(payload);

            if (res.message === "Organization Role data created successfully.") {
                setIsLoading(false)
                setToastData({
                    open: true,
                    status: 'success',
                    message: res?.message || 'Something went wrong'
                });
                setActiveScreenRoleID(res?.id)
                setSetps(2)
                fetchOrgRoleData();
            }
            else {
                setIsLoading(false)
                setToastData({
                    open: true,
                    status: 'error',
                    message: res?.response?.data?.message || res?.message || 'Something went wrong'
                });
            }
        }
    }


    //handle table toggle 
    const toggleActive = async (e, id) => {
        const tempScreenData = [...orgRoleData];
        tempScreenData.forEach(async (el) => {
            console.log("ël", el);
            if (el.id === id) {
                if (e.target.checked) {
                    el.status = 'ACTIVE';
                } else {
                    el.status = 'INACTIVE';
                }
                const payload = {
                    status: el.status,
                };
                const res = await updateOrgRoleModule(id, payload);
                console.log(res, 'resData');
                if (res.message === "Organization role data updated successfully.") {
                    setToastData({
                        open: true,
                        status: 'success',
                        message: res?.message || 'Something went wrong'
                    });
                    fetchOrgRoleData();
                }
                else {
                    fetchOrgRoleData();
                    setToastData({
                        open: true,
                        status: 'error',
                        message: res?.response?.data?.message || 'Something went wrong'
                    });
                }
            }
        });

    };

    //remove orgrole 
    const removeOrgRoleData = async (id) => {
        const res = await deleteOrgRoleModule(id);
        if (res.message === "Organization role deleted successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchOrgRoleData();
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || res?.message || 'Something went wrong'
            });
        }
    }

    const handleSetps = (value) => {
        if (setps === 2) {
            setSetps(2)
        }
        else if (setps === 1) {
            setSetps(1)
        }
        else { setSetps(value) }
    }


    //duplicate org role  
    const duplicateOrgRoleData = async (id) => {
        debugger
        const res = await duplicateOrgRoleModule(id);
        if (res.message === "Organization Role data created successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.message || 'Something went wrong'
            });
            fetchOrgRoleData();
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || res?.message || 'Something went wrong'
            });
        }
    }

    return (
        <>
            {IsLoading && <Loader />}

            {!assignToUserModal ?

                !createModal ?
                    <>
                        <Card padding={5}>
                            <Stack className='breadcrubs' justifyItems={'flex-start'} alignItems={'flex-start'}>
                                <h5> Screen Role </h5>

                                <Stack mt={2} flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'} sx={{ width: '100%' }}>
                                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                                    <button className='blue_btn' onClick={() => {
                                        setCreateModal(!createModal)
                                        setSetps(1)
                                    }} >
                                        Create Role
                                    </button>

                                </Stack>
                            </Stack>


                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 400, marginTop: "20px" }}>
                                    <Table
                                        sx={{
                                            background: 'white',
                                            borderTopLeftRadius: '10px',
                                            borderTopRightRadius: '10px',
                                            border: '1px solid #D1D9E0',
                                            borderCollapse: 'unset',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <StyledTableListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={orgRoleData?.length}
                                            onRequestSort={handleRequestSort}
                                            onSelectAllClick={handleSelectAllClick}
                                        />
                                        <TableBody>

                                            {/* super admin roles */}
                                            {combineList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                                const { id, name, role_type, status, created, description } = row;
                                                const isItemSelected = selected.indexOf(name) !== -1;
                                                return (
                                                    <StyledTableRow tabIndex={-1} >
                                                        <StyledTableCell align="left"> {name ? name : '-'} </StyledTableCell>
                                                        <StyledTableCell align="left"> {description ? description : '-'} </StyledTableCell>
                                                        <StyledTableCell align="left" style={{ width: "70px" }}>
                                                            <CustomIOSSwitch status={status} disabled />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" style={{ width: "172px" }}> {created ? moment(created).format('YYYY-MM-DD') : '-'} </StyledTableCell>
                                                        <StyledTableCell align="left" style={{ width: "172px" }}>
                                                            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                                {role_type ? role_type : '-'}
                                                                {row?.template_role &&
                                                                    <Tooltip title="To use the Template roles, please create a duplicate set of them.">
                                                                        <IconButton>
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clip-path="url(#clip0_3304_16074)">
                                                                                    <circle cx="10" cy="10" r="9.5" stroke="#909090" />
                                                                                    <path d="M10.833 5.83333C10.833 6.29333 10.4597 6.66667 9.99967 6.66667C9.53967 6.66667 9.16634 6.29333 9.16634 5.83333C9.16634 5.37333 9.53967 5 9.99967 5C10.4597 5 10.833 5.37333 10.833 5.83333ZM10.833 15.4167V9.58333C10.833 8.89417 10.2722 8.33333 9.58301 8.33333H8.74967C8.51967 8.33333 8.33301 8.52 8.33301 8.75C8.33301 8.98 8.51967 9.16667 8.74967 9.16667H9.58301C9.81217 9.16667 9.99967 9.35417 9.99967 9.58333V15.4167C9.99967 15.6467 10.1863 15.8333 10.4163 15.8333C10.6463 15.8333 10.833 15.6467 10.833 15.4167Z" fill="#909090" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_3304_16074">
                                                                                        <rect width="20" height="20" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </Stack>


                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" style={{ width: "206px" }} >
                                                            <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                                sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'default' }}
                                                            >
                                                                Permissions Assigned
                                                            </Stack>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" style={{ width: "160px" }} > NA </StyledTableCell>
                                                        <StyledTableCell align="center" style={{ width: "70px" }}>
                                                            <OrgRoleMoreMenu
                                                                onEdit={EditModuleStatus}
                                                                id={id}
                                                                data={row}
                                                                removeData={removeOrgRoleData}
                                                                onDuplicate={duplicateOrgRoleData}
                                                            />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}



                                            {/* org roles  */}
                                            {filteredRole?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                                const { id, name, role_type, status, created, description } = row;
                                                const isItemSelected = selected.indexOf(name) !== -1;
                                                return (
                                                    <StyledTableRow >
                                                        <StyledTableCell align="left"> {name ? name : '-'} </StyledTableCell>
                                                        <StyledTableCell align="left"> {description ? description : '-'} </StyledTableCell>
                                                        <StyledTableCell align="left" style={{ width: "70px" }}>
                                                            <CustomIOSSwitch handleSwitch={(e) => toggleActive(e, id)} status={status} />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" style={{ width: "172px" }}> {created ? moment(created).format('YYYY-MM-DD') : '-'} </StyledTableCell>
                                                        <StyledTableCell align="left" style={{ width: "172px" }}>
                                                            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                                {role_type ? role_type : '-'}
                                                                {row?.template_role &&
                                                                    <Tooltip title="To use the Template roles, please create a duplicate set of them.">
                                                                        <IconButton>
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clip-path="url(#clip0_3304_16074)">
                                                                                    <circle cx="10" cy="10" r="9.5" stroke="#909090" />
                                                                                    <path d="M10.833 5.83333C10.833 6.29333 10.4597 6.66667 9.99967 6.66667C9.53967 6.66667 9.16634 6.29333 9.16634 5.83333C9.16634 5.37333 9.53967 5 9.99967 5C10.4597 5 10.833 5.37333 10.833 5.83333ZM10.833 15.4167V9.58333C10.833 8.89417 10.2722 8.33333 9.58301 8.33333H8.74967C8.51967 8.33333 8.33301 8.52 8.33301 8.75C8.33301 8.98 8.51967 9.16667 8.74967 9.16667H9.58301C9.81217 9.16667 9.99967 9.35417 9.99967 9.58333V15.4167C9.99967 15.6467 10.1863 15.8333 10.4163 15.8333C10.6463 15.8333 10.833 15.6467 10.833 15.4167Z" fill="#909090" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_3304_16074">
                                                                                        <rect width="20" height="20" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </Stack>

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" style={{ width: "206px" }} >
                                                            {!row?.module?.length ?
                                                                <Button
                                                                    onClick={() => {
                                                                        setActiveScreenRoleID(id)
                                                                        setActiveRoleData(row)
                                                                        setCreateModal(true)
                                                                        setSetps(2)
                                                                    }}
                                                                    color="primary"
                                                                >
                                                                    Assign/View Module
                                                                </Button>
                                                                :
                                                                <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                                    sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        // setAssignModal(true)
                                                                        setActiveScreenRoleID(id)
                                                                        setActiveRoleData(row)
                                                                        setCreateModal(true)
                                                                        setSetps(2)
                                                                    }}
                                                                >
                                                                    Permissions Assigned
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g clip-path="url(#clip0_1839_74338)">
                                                                            <path d="M16.25 0H3.75C1.6825 0 0 1.6825 0 3.75V16.25C0 18.3175 1.6825 20 3.75 20H16.25C18.3175 20 20 18.3175 20 16.25V3.75C20 1.6825 18.3175 0 16.25 0ZM19.1667 16.25C19.1667 17.8583 17.8583 19.1667 16.25 19.1667H3.75C2.14167 19.1667 0.833333 17.8583 0.833333 16.25V3.75C0.833333 2.14167 2.14167 0.833333 3.75 0.833333H16.25C17.8583 0.833333 19.1667 2.14167 19.1667 3.75V16.25ZM12.2767 4.77667L5.02083 12.0325C4.47 12.5825 4.16667 13.315 4.16667 14.095V15.4167C4.16667 15.6467 4.35333 15.8333 4.58333 15.8333H5.905C6.6725 15.8333 7.42417 15.5217 7.9675 14.9792L15.2233 7.72333C16.0358 6.91083 16.0358 5.58917 15.2233 4.7775C14.4375 3.99 13.0633 3.99 12.2775 4.7775L12.2767 4.77667ZM7.3775 14.3892C6.99 14.7775 6.4525 14.9992 5.90417 14.9992H4.99917V14.0942C4.99917 13.5375 5.21583 13.0142 5.60917 12.6208L11.1125 7.1175L12.88 8.885L7.37667 14.3883L7.3775 14.3892ZM14.6333 7.13333L13.47 8.29667L11.7025 6.52917L12.8658 5.36583C13.3375 4.89417 14.1617 4.89417 14.6333 5.36583C15.1208 5.85333 15.1208 6.64583 14.6333 7.13333Z" fill="#303030" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_1839_74338">
                                                                                <rect width="20" height="20" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Stack>
                                                            }


                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" style={{ width: "155px" }} >
                                                            {!row?.user?.length ?
                                                                <Button
                                                                    onClick={() => {
                                                                        setAssignToUserModal(true)
                                                                        setActiveScreenRoleID(id)
                                                                    }}
                                                                    color="primary"
                                                                >
                                                                    Assign/View User
                                                                </Button>
                                                                :
                                                                <Stack gap={1} flexDirection={'row'} alignItems={'center'} p={1}
                                                                    sx={{ bgcolor: '#F6F6F6', width: 'fit-content', borderRadius: '10px', cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setAssignToUserModal(true)
                                                                        setActiveScreenRoleID(id)
                                                                    }}
                                                                >
                                                                    User Assigned
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g clip-path="url(#clip0_1839_74338)">
                                                                            <path d="M16.25 0H3.75C1.6825 0 0 1.6825 0 3.75V16.25C0 18.3175 1.6825 20 3.75 20H16.25C18.3175 20 20 18.3175 20 16.25V3.75C20 1.6825 18.3175 0 16.25 0ZM19.1667 16.25C19.1667 17.8583 17.8583 19.1667 16.25 19.1667H3.75C2.14167 19.1667 0.833333 17.8583 0.833333 16.25V3.75C0.833333 2.14167 2.14167 0.833333 3.75 0.833333H16.25C17.8583 0.833333 19.1667 2.14167 19.1667 3.75V16.25ZM12.2767 4.77667L5.02083 12.0325C4.47 12.5825 4.16667 13.315 4.16667 14.095V15.4167C4.16667 15.6467 4.35333 15.8333 4.58333 15.8333H5.905C6.6725 15.8333 7.42417 15.5217 7.9675 14.9792L15.2233 7.72333C16.0358 6.91083 16.0358 5.58917 15.2233 4.7775C14.4375 3.99 13.0633 3.99 12.2775 4.7775L12.2767 4.77667ZM7.3775 14.3892C6.99 14.7775 6.4525 14.9992 5.90417 14.9992H4.99917V14.0942C4.99917 13.5375 5.21583 13.0142 5.60917 12.6208L11.1125 7.1175L12.88 8.885L7.37667 14.3883L7.3775 14.3892ZM14.6333 7.13333L13.47 8.29667L11.7025 6.52917L12.8658 5.36583C13.3375 4.89417 14.1617 4.89417 14.6333 5.36583C15.1208 5.85333 15.1208 6.64583 14.6333 7.13333Z" fill="#303030" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_1839_74338">
                                                                                <rect width="20" height="20" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Stack>
                                                            }

                                                        </StyledTableCell>

                                                        <StyledTableCell align="center" style={{ width: "70px" }}>
                                                            <OrgRoleMoreMenu
                                                                onEdit={EditModuleStatus}
                                                                id={id}
                                                                data={row}
                                                                removeData={removeOrgRoleData}
                                                                onDuplicate={duplicateOrgRoleData}
                                                            />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                            {emptyRows > 0 && (
                                                <StyledTableRow style={{ height: 53 * emptyRows }}>
                                                    <StyledTableCell colSpan={13} />
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                        {isDataNotFound && (
                                            <TableBody>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                                        <SearchNotFound searchQuery={filterName} />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={orgRoleData?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Card >


                        <NewModalComponent
                            width="100%"
                            maxWidth="350px"
                            open={createModal}
                            handleClose={() => handelCloseModal()}
                        >
                            <Card>
                                <Stack px={3} pt={3} direction="column" justifyContent="space-between" alignItems="center" spacing={1}>
                                    <Stack mt={1} sx={{ width: '100%' }} alignContent={'flex-start'}>
                                        <h4>{isEdit ? 'Update' : 'Create'} Screen Role</h4>
                                    </Stack>
                                    <form onSubmit={handleCreateUpdateModule}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            style={{ width: '300px' }}
                                            type="text"
                                            label="Name"
                                            variant="outlined"
                                            value={orgRoleDataValue?.name}
                                            name="name"
                                            onChange={handleChange}
                                            required
                                        />

                                        <TextField
                                            fullWidth
                                            size="small"
                                            style={{ width: '300px', marginTop: '16px' }}
                                            type="text"
                                            label="Role Type"
                                            variant="outlined"
                                            value={orgRoleDataValue?.role_type}
                                            name="role_type"
                                            onChange={handleChange}
                                            required
                                        />

                                        <Stack direction="row"
                                            spacing={2}
                                            mb={2}
                                            mt={2}
                                            sx={{
                                                border: ' 1px solid #D1D9E0',
                                                borderRadius: '10px',
                                                padding: '10px 14px'
                                            }}
                                            alignItems='center'
                                            justifyContent={'space-between'}
                                        >
                                            <label style={{ color: '#606060', fontWeight: '400' }}> Status </label>

                                            <CustomIOSSwitch
                                                handleSwitch={(e) => handleSwitch('status', e.target.checked)}
                                                status={orgRoleDataValue.status}
                                            />
                                        </Stack>

                                        <Stack
                                            className='updatelicense_btn'
                                            flexDirection={'row'}
                                            justifyContent={'flex-end'}
                                            gap={1} style={{ padding: "14px 0", paddingTop: "0", width: "100%" }}>
                                            <button className='outline_btn' onClick={handelCloseModal}>
                                                Cancel
                                            </button>
                                            <button className='blue_btn'
                                                sx={{ width: "100%" }}
                                                onClick={() => handleCreateUpdateModule()}>
                                                {isEdit ? "Update" : "Create"}
                                            </button>
                                        </Stack>
                                    </form>
                                </Stack>
                            </Card>
                        </NewModalComponent >


                        {/* {assignModal && <AssignModuleToRolePopup id={activeScreenRoleID} open={assignModal} handleClose={() => handelCloseModal()} />} */}


                        {/* {
                departmentModule &&
                <AssignDepartmentToRolePopup
                    open={departmentModule}
                    handleClose={() => handelCloseModal()}
                    activeScreenRoleID={activeScreenRoleID}
                />
            }

            {
                designationModule &&
                <AssignDesignationToRolePopup
                    open={designationModule}
                    handleClose={() => handelCloseModal()}
                    activeScreenRoleID={activeScreenRoleID}
                />
            }


            {
                practiceAreaModule &&
                <AssignPracticeAreaToRolePopup
                    open={practiceAreaModule}
                    handleClose={() => handelCloseModal()}
                    activeScreenRoleID={activeScreenRoleID}
                />
            } */}
                    </>

                    :


                    <Stack className='role_setps'>
                        <Stack className='role_setps_wrp'>
                            <Stack px={3} pt={3}>
                                <ul>
                                    <li
                                        className={setps === 1 ? 'active' : ''}
                                        onClick={() => handleSetps(1)}
                                    >
                                        1. Basics
                                    </li>
                                    <li
                                        className={setps === 2 ? 'active' : ''}
                                        onClick={() => handleSetps(2)}
                                    >
                                        2. Set Permissions
                                    </li>
                                </ul>
                            </Stack>
                        </Stack>

                        {setps === 1 ?
                            <form onSubmit={handleCreateUpdateModule} style={{ height: '100%' }}>
                                <Grid container spacing={1} sx={{ maxWidth: '480px', height: 'calc(100% - 69px)' }} alignContent={'flex-start'} px={3} pt={3}>
                                    <Grid item xs={8}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            style={{ width: '100%' }}
                                            type="text"
                                            label="Role Name"
                                            variant="outlined"
                                            value={orgRoleDataValue?.name}
                                            name="name"
                                            onChange={handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4} alignContent={'center'}>
                                        <Stack direction="row" alignItems='center' spacing={2} >
                                            <label style={{ color: '##101010', fontWeight: '700' }}> Role Status </label>
                                            <CustomIOSSwitch
                                                handleSwitch={(e) => handleSwitch('status', e.target.checked)}
                                                status={orgRoleDataValue.status}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} alignContent={'center'}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            style={{ width: '100%', marginTop: '16px' }}
                                            type="text"
                                            label="Description"
                                            variant="outlined"
                                            value={orgRoleDataValue?.description}
                                            name="description"
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Stack
                                    p={2}
                                    className='updatelicense_btn'
                                    flexDirection={'row'}
                                    justifyContent={'flex-end'}
                                    gap={1}
                                    style={{
                                        width: "100%",
                                        boxShadow: '0px -2px 16px 0px #0000001F'
                                    }}
                                >
                                    <button className='outline_btn' onClick={handelCloseModal}> Cancel </button>
                                    <button className='blue_btn'
                                        disabled={orgRoleDataValue?.name.length === 0}
                                        sx={{ width: "100%" }}
                                        onClick={() => handleCreateUpdateModule()}>
                                        {isEdit ? "Update" : "Create"}
                                    </button>
                                </Stack>
                            </form >

                            :

                            <AssignModalScreenAction
                                handleClose={() => handelCloseModal()}
                                roleData={activeRoleData}
                                activeScreenRoleID={activeScreenRoleID}
                                fetchOrgRoleData={fetchOrgRoleData}
                            />

                        }


                    </Stack >
                :
                <Stack className='role_setps'>
                    <AssignRoleToUserPopup
                        id={activeScreenRoleID}
                        open={assignToUserModal}
                        handleClose={() => handelCloseModal()}
                        fetchOrgRoleData={fetchOrgRoleData}
                    />
                </Stack>
            }


            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />

        </>

    )
}

export default OrganizationRoleMaster
