import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from 'components/Iconify';

// ----------------------------------------------------------------------

const OrgRoleMoreMenu = ({ onEdit, id, data, removeData, onDuplicate }) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    debugger

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {/* // user delete functinality for Super Admin */}
                {(data.role_type !== "SUBSCRIPTION" && !data.template_role) ?
                    <>
                        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => removeData(id)}>
                            {/* <ListItemIcon>
                        <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                    </ListItemIcon> */}
                            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
                        </MenuItem>

                        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => onEdit(id, data)}>
                            {/* <ListItemIcon>
                        <Iconify icon="eva:edit-fill" width={24} height={24} />
                    </ListItemIcon> */}
                            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
                        </MenuItem>


                        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onDuplicate(id)}                        >

                            <ListItemText primary="Duplicate" primaryTypographyProps={{ variant: 'body2' }} />
                        </MenuItem>
                    </>
                    :
                    <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onDuplicate(id)} >
                        {/* <ListItemIcon>
                        <Iconify icon="eva:edit-fill" width={24} height={24} />
                    </ListItemIcon> */}
                        <ListItemText primary="Duplicate" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                }
            </Menu>
        </>
    );
};

export default OrgRoleMoreMenu;
