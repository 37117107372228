import { Box, Button, Card, CircularProgress, Grid, IconButton, Modal, OutlinedInput, Stack, Table, TableBody, TableCell, TablePagination, TableRow, tableCellClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useAccount } from 'context/AccountProvider';
import Toast from 'components/Toast';
import NoSubscriptions from './NoSubscriptions';
import moment from 'moment';
import { getAllSubscription } from 'services/ZohoSubscriptions';
import SearchNotFound from 'components/SearchNotFound';
import StyledTableListHead from 'views/StyledTable/StyledTableListHead';
import SubscriptionMoreMenu from './SubscriptionMoreMenu';

// ----------------------------------------------------------------------



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    'td ': {
        borderRight: "1px solid #D1D9E0 !important"
    },
    'th ': {
        borderBottom: "1px solid #D1D9E0 !important"
    },

}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    '&:last-child': {
        borderRight: '0 !important',
    },
    fontSize: '14px',
    fontWeight: 500,
    color: '#404040',
    padding: '10px !important',

}));


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 260,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    // '&.Mui-focused': { width: 320 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `#00000033 !important`,
        top: "0",
    },
    '& fieldset legend': {
        float: "left",

    }
}));


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return array.filter((_) => _.name?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1)
    }
    return stabilizedThis?.map((el) => el[0]);
}


const TABLE_HEAD = [
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'name', label: 'Invoice#', alignRight: false },
    { id: 'add_service', label: 'Status', alignRight: false },
    { id: 'fee_type', label: 'Amount', alignRight: false },
    { id: 'sharepoint_link', label: 'Due Date', alignRight: false },
    // { id: ' ', label: '', alignRight: false }
];




const ZohoInvoices = () => {
    const { userDetails } = useAccount();
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    // tableState
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [filterName, setFilterName] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [defaultqty, setDefaultqty] = useState(1)
    const [openModal, setOpenModal] = useState(false)
    const [selectedPurchase, setSelectedPurchase] = useState([])


    console.log("userDetails", userDetails);
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    useEffect(() => {
        if (userDetails.id) {
            fetchSubscriptionList()
        }
    }, [userDetails])


    const fetchSubscriptionList = async () => {
        setIsLoading(true)
        const res = await getAllSubscription(userDetails.id);
        if (res?.data) {
            setSubscriptionList(res?.data)
            setIsLoading(false)
        }
        else {
            setSubscriptionList([])
            setIsLoading(false)
        }
    }



    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subscriptionList?.length) : 0;

    const filteredSubscriptionList = applySortFilter(subscriptionList, getComparator(order, orderBy), filterName);

    const isDataNotFound = filteredSubscriptionList?.length === 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };



    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleOpenModal = async () => {
        setOpenModal(true);
    }


    const handleUpdate = (item) => {
        setOpenModal(true)
        setSelectedPurchase(item)
    }
    const handleCancel = () => {

    }





    const handleQty = (name) => {
        if (name === 'plus') { setDefaultqty(defaultqty + 1) }
        else {
            if (defaultqty > 1) { setDefaultqty(defaultqty - 1) }
            else { setDefaultqty(1) }

        }
    }



    const onButtonClick = (url, name) => {
        fetch(url).then((response) => {
            response.blob().then((blob) => {

                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = `${name}.pdf`;
                alink.click();
            });
        });
    };



    return (
        <>
            <Stack sx={{ height: '100%' }} >
                <Stack className='breadcrubs' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <h5> All Invoices </h5>
                </Stack>

                {isLoading ?

                    <Stack alignItems={'center'} justifyContent={'center'} style={{ height: '100%', minWidth: '352px' }}>
                        <CircularProgress />
                    </Stack>
                    :
                    subscriptionList.length ?
                        <div className="mysubscription">
                            <div style={{ overflow: "auto", maxHeight: "550px", marginTop: "8px", }}>

                                {/* <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} /> */}
                                <Table

                                    sx={{
                                        background: 'white',
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        border: '1px solid #D1D9E0',
                                        borderCollapse: 'unset',
                                        overflow: 'hidden'
                                    }}
                                    aria-label="simple table">
                                    <StyledTableListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={subscriptionList?.length}
                                        // numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>

                                        {filteredSubscriptionList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                                            return (
                                                <StyledTableRow
                                                    key={item.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <StyledTableCell>
                                                        {item.data.invoice.invoice_date}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <a onClick={() => onButtonClick(`https://dev-user-access.kronicle.in/zoho/invoice/${item.data.invoice.invoice_id}`, item.data.invoice.invoice_number)}
                                                            style={{ fontSize: '14px', fontWeight: 500, color: '#216DDE', textDecoration: 'none', cursor: 'pointer' }}
                                                        >
                                                            {item.data.invoice.invoice_number} &nbsp;
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.3059 9.04167L11.2101 11.1833C10.8884 11.505 10.4667 11.6658 10.0459 11.6667H10.0442C9.62424 11.6667 9.20424 11.5067 8.88424 11.1867L6.78507 9.04167C6.62424 8.8775 6.62757 8.61333 6.79174 8.4525C6.95674 8.29333 7.22091 8.295 7.38091 8.45917L9.47674 10.6008C9.52341 10.6475 9.57424 10.6867 9.62924 10.7192V2.91667C9.62924 2.68667 9.81591 2.5 10.0459 2.5C10.2759 2.5 10.4626 2.68667 10.4626 2.91667V10.7183C10.5176 10.685 10.5701 10.645 10.6184 10.5975C10.6667 10.55 12.7109 8.45917 12.7109 8.45917C12.8717 8.295 13.1359 8.29333 13.3001 8.4525C13.4642 8.61333 13.4667 8.8775 13.3059 9.04167Z" fill="#216DDE" />
                                                                <path d="M0.833333 11.666V13.3327C0.833333 15.1736 2.32572 16.666 4.16667 16.666H15.8333C17.6743 16.666 19.1667 15.1736 19.1667 13.3327V10.8327C19.1667 10.8327 19.1667 10.416 19.5833 10.416C20 10.416 20 10.8327 20 10.8327V13.3327C20 15.6339 18.1345 17.4993 15.8333 17.4993H4.16667C1.86548 17.4993 0 15.6339 0 13.3327V10.8327C0 10.8327 0.0528171 10.416 0.416667 10.416C0.780516 10.416 0.833333 10.8327 0.833333 10.8327V11.666Z" fill="#216DDE" />
                                                            </svg>
                                                        </a>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <span style={{ color: item.data.invoice.status === 'paid' ? '#48B681' : 'red' }}>
                                                            {item.data.invoice.status}
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {item.data.invoice.currency_symbol}{item.data.invoice.payment_made}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {item.data.invoice.due_date}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })
                                        }

                                    </TableBody>
                                    {isDataNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}

                                </Table>
                            </div>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={subscriptionList?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div >

                        :

                        <NoSubscriptions />
                }

            </Stack>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <Box
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        border: '1px solid #D1D9E0',
                        transform: 'translate(-50%, -50%)',
                        width: 'calc(100% - 30px)',
                        maxWidth: '650px',
                        backgroundColor: '#fff',
                        maxHeight: 'calc(100% - 30px)',
                        overflow: 'auto',
                        borderRadius: '10px'
                    }}
                    className='newpopup'
                >
                    <Card className=' updatelicense'>
                        <Stack sx={{ padding: '16px 20px' }}>
                            <h5>  Update No of License </h5>
                        </Stack>
                        <Stack sx={{ padding: '20px' }}>
                            <div className='updatelicense_table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th> Plan Name </th>
                                            <th> No of License </th>
                                            <th> Add new License </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> Standard ( P2 ) </td>
                                            <td> 50 </td>
                                            <td className='updatelicense_table_qty'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleQty('minus')}>
                                                    <path d="M20.5 11H3.5C3.224 11 3 11.224 3 11.5C3 11.776 3.224 12 3.5 12H20.5C20.776 12 21 11.776 21 11.5C21 11.224 20.776 11 20.5 11Z" fill="#303030" />
                                                </svg>
                                                <input type='number' value={defaultqty} onChange={(e) => setDefaultqty(+e.target.value)} />

                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleQty('plus')}>
                                                    <path d="M0 9C0 8.724 0.224 8.5 0.5 8.5H8.5V0.5C8.5 0.224 8.724 0 9 0C9.276 0 9.5 0.224 9.5 0.5V8.5H17.5C17.776 8.5 18 8.724 18 9C18 9.276 17.776 9.5 17.5 9.5H9.5V17.5C9.5 17.776 9.276 18 9 18C8.724 18 8.5 17.776 8.5 17.5V9.5H0.5C0.224 9.5 0 9.276 0 9Z" fill="#303030" />
                                                </svg>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='updatelicense_sum'>
                                <div className='updatelicense_sum_item'>
                                    <div className='updatelicense_sum_item_left'>
                                        <strong> Immediate Charges </strong>
                                    </div>
                                    <div className='updatelicense_sum_item_right'>
                                        <strong> ₹10,600 </strong>
                                        <label> on 06/06/2024 </label>
                                    </div>
                                </div>
                                <div className='updatelicense_sum_item'>
                                    <div className='updatelicense_sum_item_left'>
                                        <strong>  Recurring Charges </strong>
                                    </div>
                                    <div className='updatelicense_sum_item_right'>
                                        <strong> ₹10,600 </strong>
                                        <label> Billed per month starting from 07/07/2024 </label>
                                    </div>
                                </div>
                            </div>
                        </Stack>
                        <Stack
                            className='updatelicense_btn'
                            flexDirection={'row'} justifyContent={'flex-end'} gap={1} style={{ padding: "10px 20px", paddingTop: "0" }}>
                            <button className='outline_btn' onClick={handleCloseModal}>
                                Cancel
                            </button>
                            <button className='blue_btn'>
                                Update
                            </button>
                        </Stack>
                    </Card>
                </Box>
            </Modal>


            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    );
};

export default ZohoInvoices;
